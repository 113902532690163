import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../components/image-hotspots-modal-template"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import CardFlip from "../../../components/card-flip"
import Inline from "../../../components/inline"
import Text from "../../../components/text"
import Trivia from "../../../components/trivia"
import VideoPlayer from "../../../components/video-player"

const HoelderlinLiebtDasIstPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        portraitRahmen: file(
          relativePath: { eq: "sprachen/portrait-rahmen.png" }
        ) {
          ...largeImage
        }
        lockeSchiller: file(
          relativePath: { eq: "hoelderlin-liebt/locke-schiller.png" }
        ) {
          ...avatarImageLarge
        }
        lockeSchillerPortrait: file(
          relativePath: { eq: "hoelderlin-liebt/locke-schiller-portrait.png" }
        ) {
          ...avatarImageLarge
        }
        lockeHoelderlin: file(
          relativePath: { eq: "hoelderlin-liebt/locke-hoelderlin.png" }
        ) {
          ...avatarImageLarge
        }
        lockeHoelderlinPortrait: file(
          relativePath: { eq: "hoelderlin-liebt/locke-hoelderlin-portrait.png" }
        ) {
          ...avatarImageLarge
        }
        lockeKoerner: file(
          relativePath: { eq: "hoelderlin-liebt/locke-koerner.png" }
        ) {
          ...avatarImageLarge
        }
        lockeKoernerPortrait: file(
          relativePath: { eq: "hoelderlin-liebt/locke-koerner-portrait.png" }
        ) {
          ...avatarImageLarge
        }
        lockeMoerike: file(
          relativePath: { eq: "hoelderlin-liebt/locke-moerike.png" }
        ) {
          ...avatarImageLarge
        }
        lockeMoerikePortrait: file(
          relativePath: { eq: "hoelderlin-liebt/locke-moerike-portrait.png" }
        ) {
          ...avatarImageLarge
        }
        posterDichterlocken: file(
          relativePath: { eq: "hoelderlin-liebt/poster-dichterlocken.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt"
        description="Der Dichter Friedrich Hölderlin liebt die Wörter. Die schwierigen ganz besonders. Seine Gedichte sind voll von ungewöhnlichen Wörtern. Das macht sie besonders, aber auch schwer zu verstehen. Manche sagen sogar: Es sind die schwierigsten Gedichte in deutscher Sprache. Die Gedichte von Hölderlin sind das Gegenteil von einfach. Das soll sich mit unserer Sonder-Ausstellung im Museum Hölderlinturm nun ändern."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "Das ist Hölderlin",
                link: "/sonderausstellungen/hoelderlin-liebt/das-ist-hoelderlin",
              },
            ]}
          />
          <PageTitle>Das ist Hölderlin</PageTitle>
          <Paragraph dropcap={true}>
            Friedrich Hölderlin wurde am 20. März 1770 in Lauffen am Neckar
            geboren. <br />
            Vor mehr als 250 Jahren. <br />
            Zu dieser Zeit gibt es noch keine Fotografien. <br />
            Wie Hölderlin ausgesehen hat, <br />
            wissen wir nur von ein paar wenigen gemalten Bildern.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            So sieht er aus
          </Heading>
          <Paragraph>
            Das bekannteste Bild zeigt Hölderlin im Alter von 22 Jahren. <br />
            Der Künstler Franz Carl Hiemer hat es gemalt. <br />
            Er war ein Freund von Hölderlin.
          </Paragraph>
          <Constrain>
            <ImageHotspots
              image={
                <Image
                  image={data.portraitRahmen.childImageSharp.gatsbyImageData}
                  alt="Ein Ölgemälde zeigt Friedrich Hölderlin mit langen, hellgrauen Haaren. Er trägt ein Hemd und ein Jackett"
                />
              }
            >
              <ImageHotspot x={55} y={25} name="hoelderlin-01">
                <ImageHotspotsModalTemplate title="Haare">
                  <Paragraph>
                    Hölderlins Haare gehen bis über die Schultern. <br />
                    An den Seiten sind sie zu Locken gewickelt. <br />
                    Obwohl Hölderlin auf dem Bild erst 22 Jahre alt ist, <br />
                    sind seine Haare hellgrau.
                    <br />
                    Er trägt eine gepuderte Perücke. <br />
                    Das ist zu Hölderlins Zeit modern. <br />
                    Die Perücke zeigt, dass Hölderlin aus einer angesehenen
                    Familie stammt.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={32} y={40} name="hoelderlin-02">
                <ImageHotspotsModalTemplate title="Nase">
                  <Paragraph>
                    Hölderlin hat eine lange und schmale Nase. <br />
                    Sie ist sehr gerade.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>

              <ImageHotspot x={53} y={35} name="hoelderlin-03">
                <ImageHotspotsModalTemplate title="Augen">
                  <Paragraph>
                    Hölderlins Augen blicken zur Seite.
                    <br />
                    Sie sind braun.
                    <br />
                    Seine Augenbrauen sind lang und gepflegt.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>

              <ImageHotspot x={60} y={50} name="hoelderlin-04">
                <ImageHotspotsModalTemplate title="Hemd">
                  <Paragraph>
                    Hölderlin trägt eine braune Jacke mit feinen Streifen.{" "}
                    <br />
                    Die Knöpfe der Jacke sind offen.
                    <br />
                    Darunter trägt Hölderlin ein weißes Hemd. <br />
                    Das Hemd hat einen weiten, offenen Kragen mit Rüschen.{" "}
                    <br />
                    Der offene Kragen ist ein Zeichen für Freiheit. <br />
                    In der Zeit der Französischen Revolution wird das zur Mode.{" "}
                    <br />
                    Jahre zuvor trägt man den Kragen noch nach oben hin eng
                    geschlossen .
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>

              <ImageHotspot x={34} y={28} name="hoelderlin-05">
                <ImageHotspotsModalTemplate title="Stirn">
                  <Paragraph>
                    Hölderlin hat eine hohe Stirn. <br />
                    Sein Haaransatz verläuft in einer geraden Linie <br />
                    und geht an den Schläfen eckig nach unten.
                    <br />
                    Das wirkt sehr gepflegt und anmutig.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={50} y={40} name="hoelderlin-06">
                <ImageHotspotsModalTemplate title="Gesicht">
                  <Paragraph>
                    Hölderlin hat ein schmales Gesicht und helle Haut. <br />
                    Sein Kinn und seine Wangenknochen machen sein Gesicht
                    kantig. <br />
                    Hölderlin sieht ruhig und selbstbewusst aus. <br />
                    Seine Schwester und seine Mutter finden, dass das Gemälde
                    ihm nicht ähnlich sieht.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={37} y={48} name="hoelderlin-07">
                <ImageHotspotsModalTemplate title="Lippen">
                  <Paragraph>
                    Hölderlins Lippen sind schmal.
                    <br />
                    Seine Oberlippe hat die Form einer feinen Wellenlinie.
                    <br />
                    Über dem Kinn hat Hölderlin ein Grübchen.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
            </ImageHotspots>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Dichter-Locken
          </Heading>
          <VideoPlayer
            src="/hoelderlin-liebt/dichterlocken"
            poster={data.posterDichterlocken}
          />
          <Paragraph>
            Schon auf den frühen Bildern hat Hölderlin graue Haare. <br />
            Er trägt eine Perücke. <br />
            Zu Hölderlins Zeit gilt das als schick. <br />
            Perücken zeigen, wie reich die Menschen sind. <br />
            Bei den Dichtern sind Locken-Haare ganz besonders beliebt. <br />
            Sie erinnern an den griechischen Gott Apoll.
            <br />
            Apoll ist der Gott der Künste, Musik und Dichtkunst.
            <br />
            Dichter verschenken ihre Locken <br />
            als Andenken an Freunde und Verehrer. <br />
            Von Friedrich Schiller gibt es noch heute zahlreiche Locken. <br />
            Von Friedrich Hölderlin dagegen gibt es nur eine einzige Haarlocke.{" "}
          </Paragraph>
          <Constrain align="left">
            <Trivia title="Hier können Sie raten:">
              <Paragraph>
                Welche Locke gehört zu Hölderlin? <br />
                Klicken Sie auf die Bilder, um die Auflösung zu sehen.{" "}
              </Paragraph>
            </Trivia>
          </Constrain>
          <Inline alignX="center" alignY="center">
            <CardFlip
              width={[48, 72]}
              height={[48, 72]}
              front={
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Image
                    image={data.lockeSchiller.childImageSharp.gatsbyImageData}
                    alt="Ein Medaillon mit Goldrand. Darin liegen dünne dunkelblonde Haare."
                  />
                </Box>
              }
              back={
                <Box p={[3, 6, 12]}>
                  <Stack space={3} alignX="center">
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: [24, 48],
                        height: [24, 48],
                        borderRadius: "9999em",
                      }}
                    >
                      <Image
                        image={
                          data.lockeSchillerPortrait.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Ein Ölgemälde von Friedrich Schiller. Er hat rotblonde Haare und stützt seinen Kopf auf die linke Hand."
                      />
                    </Box>
                    <Text>Das ist die Locke von Friedrich Schiller</Text>
                  </Stack>
                </Box>
              }
            ></CardFlip>
            <CardFlip
              width={[48, 72]}
              height={[48, 72]}
              front={
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Image
                    image={data.lockeHoelderlin.childImageSharp.gatsbyImageData}
                    alt="Ein rundes Medaillon mit schwarzem Rand. Darin liegt eine dunkelbraune Haarsträhne mit einer weißen Schleife."
                  />
                </Box>
              }
              back={
                <Box p={[3, 6, 12]}>
                  <Stack space={3} alignX="center">
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: [24, 48],
                        height: [24, 48],
                        borderRadius: "9999em",
                      }}
                    >
                      <Image
                        image={
                          data.lockeHoelderlinPortrait.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Ein Ölgemälde zeigt Friedrich Hölderlin mit langen, hellgrauen Haaren. Er trägt ein Hemd und ein Jackett"
                      />
                    </Box>
                    <Text>Das ist die Locke von Friedrich Hölderlin</Text>
                  </Stack>
                </Box>
              }
            ></CardFlip>
            <CardFlip
              width={[48, 72]}
              height={[48, 72]}
              front={
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Image
                    image={data.lockeMoerike.childImageSharp.gatsbyImageData}
                    alt="Ein Medaillon mit einer hellbraunen Haarsträhne. Sie wird von einer goldenen Schleife zusammengehalten."
                  />
                </Box>
              }
              back={
                <Box p={[3, 6, 12]}>
                  <Stack space={3} alignX="center">
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: [24, 48],
                        height: [24, 48],
                        borderRadius: "9999em",
                      }}
                    >
                      <Image
                        image={
                          data.lockeMoerikePortrait.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Eduard Mörike hat lockiges Haar und trägt eine Brille."
                      />
                    </Box>
                    <Text>Das ist die Locke von Eduard Mörike</Text>
                  </Stack>
                </Box>
              }
            ></CardFlip>
            <CardFlip
              width={[48, 72]}
              height={[48, 72]}
              front={
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Image
                    image={data.lockeKoerner.childImageSharp.gatsbyImageData}
                    alt="Eine blonde Haarsträhne auf einer Buchseite. Die Ecken der Seite sind nach innen gefaltet."
                  />
                </Box>
              }
              back={
                <Box p={[3, 6, 12]}>
                  <Stack space={3} alignX="center">
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: [24, 48],
                        height: [24, 48],
                        borderRadius: "9999em",
                      }}
                    >
                      <Image
                        image={
                          data.lockeKoernerPortrait.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Christian Gottfried Körner hat hellgraues, gewelltes Haar. Er trägt ein Hemd mit Rüschen und eine blaue Jacke."
                      />
                    </Box>
                    <Text>
                      Das ist die Locke von Christian Gottfried Körner
                    </Text>
                  </Stack>
                </Box>
              }
            ></CardFlip>
          </Inline>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtDasIstPage
